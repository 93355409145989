import React from 'react';
import {Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink} from './NavbarElements';

const Navbar = () => {
return (
	<>
	<Nav>
		<Bars />

		<NavMenu>
		<NavLink to='/' activeStyle>
			Home
		</NavLink>
		<NavLink to='/about' activeStyle>
			O Nama 
		</NavLink>
		<NavLink to='/sign-up' activeStyle>
			Registracija
		</NavLink>
		<NavLink to='/predstavnik' activeStyle>
			Predstavnik
		</NavLink>
		{/* Second Nav */}
		{/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
		</NavMenu>
		<NavBtn>
		<NavBtnLink to='/signin'>Prijava</NavBtnLink>
		</NavBtn>
	</Nav>
	</>
);
};

export default Navbar;
