import React from 'react';

const Predstavnik = () => {
return (
	<div
	style={{
		display: 'flex',
		justifyContent: 'Right',
		alignItems: 'Right',
		height: '100vh'
	}}
	>
	<h1>Welcome to GeeksforGeeks Team</h1>
	</div>
);
};

export default Predstavnik;
